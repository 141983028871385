<template>
    <div>
        <div class="border rounded main-content">
            <div class="card-header d-flex justify-content-between">
                <h5><strong>Homework</strong></h5>
                <div class="d-flex justify-content-between">
                    <p class="pr-1" style="font-size: small;"><strong>school</strong></p> 
                    <feather-icon
                        icon="ChevronDownIcon"
                        size="20"
                    />
                    <!-- <vue-apex-charts
                        type="radialBar"
                        height="100"
                        width="100"
                        :options="goalOverviewRadialBarProgress.chartOptions"
                        :series="goalOverviewRadialBarProgress.series"
                    /> -->
                </div>
            </div>
            <div v-for="(item,key) in items" :key="key">

                <div class="d-flex justify-content-between align-items-center card-body p-1">
                    <div class="d-flex justify-content-left">

                        <div class="prof-card">
                            <img class="img_prof" src="https://hustlelab.vero-cloud.com/api/users/image/avatar/Number=5/k8fa" alt="img">
                        </div>
                        <div  style="padding-top: 0.2rem;padding-left: 0.4rem;"> 
                            <div style="font-size: small;" class="m-0"><strong  >Marketing research assignment</strong></div>
                            <span style="font-size: small;" >20 Aug 2023</span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-left">
                        <feather-icon
                            icon="CalendarIcon"
                            size="20"
                        />
                        <div style="padding-left: 0.4rem;" class="pr-1">
                            <span style="font-size: small;" >Due: 20 Aug 2023</span>
                        </div>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="20"
                        />
                    </div>
                    <!-- <hr> -->
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>

import {
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
} from "bootstrap-vue";
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from "vuex";
export default {
  components: {
    VueApexCharts,
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
  },
  data() {
    return {
        items:[1,2,3]
    }
  },
  computed:{
        ...mapGetters({
            // statistics: "studentDashboard/statistics",
            // loadStatistics: "studentDashboard/loadStatistics",
        }),
        goalOverviewRadialBarProgress(){
            return {
            series: [2],
            chartOptions: {
            chart: {
                // height: 90,
                type: 'radialBar',
                toolbar: {
                show: false
                }
            },
            plotOptions: {
                radialBar: {
                startAngle: -135,
                endAngle: 225,
                    hollow: {
                    margin: 0,
                    size: '65%',
                    background: this.$store.state.appConfig.layout.skin == 'dark' ? '#000' : '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                    dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 5,
                    opacity: 0.24
                    }
                },
                track: {
                    background: this.$store.state.appConfig.layout.skin == 'dark' ? '#000' : '#fff',
                    strokeWidth: '60%',
                    margin: 0, // margin is in pixels
                    dropShadow: {
                    enabled: true,
                    bottom: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.55
                    }
                },
            
                dataLabels: {
                    show: true,
                    name: {
                    offsetY: 0,
                    show: false,
                    color: '#888',
                    fontSize: '22px'
                    },
                    value: {
                    formatter: function(val) {
                        return parseInt(val)+'%';
                    },
                    color: this.$store.state.appConfig.layout.skin == 'dark' ? '#fff' : '#111',
                    fontSize: '15px',
                    offsetY: 5,
                    show: true,
                    }
                }
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 1,
                gradientToColors: ['#E84185'],
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
                }
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['Percent'],
            },
            }
        }
    }
}
</script>
<style scoped>
    .border{
        border: 2px solid #585858 !important;
        /* border-radius: 1rem !important; */
    }
    .main-content{
        font-size: medium;
    }
    .card-header{
        background-color: #0c0b0b;
        height: 4rem;
        /* border-radius: 1rem !important; */
    }
    /* .prof-card{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 0.2rem solid #0c0b0b;
    } */
    .img_prof{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 0.2rem solid #0c0b0b;
        background: linear-gradient(135deg, #692295, #e84185);
    }
    .card-body{
        background-color: #1c1c1c;
        border-bottom: 0.1rem solid #585858; 
        /* border-radius: 1rem !important; */
    }
    
</style>
<template>
    <div>
        <student-statistics/>
        <div class="p-2">
          <b-row>
              <b-col class="col-md-7">
                <recommended-quests/>
                <new-challenges/>
              </b-col>
              <b-col class="col-md-5">
                <homework-card/>
                <top-points/>
              </b-col>
          </b-row>
        </div>
    </div>
</template>

<script>
import studentStatistics from '@/views/dashboard/studentDashboard/studentStatistics.vue'
import homeworkCard from '@/views/dashboard/studentDashboard/homeworkCard.vue'
import topPoints from '@/views/dashboard/studentDashboard/topPoints.vue'
import recommendedQuests from '@/views/dashboard/studentDashboard/recommendedQuests.vue'

import {
    BSpinner,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BFormCheckbox,
        BCard,
        BCardText,
        BCardTitle,
        BButton,
        BLink,
    } from "bootstrap-vue";
import NewChallenges from '@/views/dashboard/studentDashboard/newChallenges.vue'

export default {
  
  components: {
    NewChallenges,
    recommendedQuests,
    studentStatistics,
    homeworkCard,
    topPoints,
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
    },
  data() {
    return {
      
    }
  },
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <b-card class="rounded-lg p-1">
          <b-col>
            <b-row class="d-flex mb-1">
              <h3 class="font-weight-bolder">Class overview</h3>
              <p>
                <b-link
                  class="alert-link cutom-alert-link ml-1 font-weight-normal"
                  :to="{ name: 'students' }"
                > 
                  View students
                  <feather-icon icon="ArrowRightIcon" size="18" />
                </b-link>
              </p>
            </b-row>
            <b-row>
              <b-col class="col-md-3 p-50">
                <div class="d-flex border p-2 rounded-lg shadow-sm">
                  <div>
                    <img src="@/assets/images/dashboard/1.png" alt />
                  </div>
                  <div class="px-1">
                    <p class="m-0">Overall engagement</p>
                    <div class="d-flex align-item-center justify-content-start">
                      <h3 class="font-weight-bolder m-0">18h</h3>&nbsp; / &nbsp;
                      <p class="m-0">weekly</p>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col class="col-md-3 p-50">
                <div class="d-flex border p-2 rounded-lg shadow-sm">
                  <div>
                    <img src="@/assets/images/dashboard/2.png" alt />
                  </div>
                  <div class="px-1">
                    <p class="m-0">Points scored</p>
                    <div class="d-flex align-item-center justify-content-start">
                      <h3 class="font-weight-bolder m-0">3 430</h3>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col class="col-md-3 p-50">
                <div class="d-flex border p-2 rounded-lg shadow-sm">
                  <div>
                    <img src="@/assets/images/dashboard/3.png" alt />
                  </div>
                  <div class="px-1">
                    <p class="m-0">Overall progress</p>
                    <div class="d-flex align-item-center justify-content-start">
                      <h3 class="font-weight-bolder m-0">80%</h3>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col class="col-md-3 p-50">
                <div class="d-flex border p-2 rounded-lg shadow-sm">
                  <div>
                    <img src="@/assets/images/dashboard/4.png" alt />
                  </div>
                  <div class="px-1">
                    <p class="m-0">Missed lessons</p>
                    <div class="d-flex align-item-center justify-content-start">
                      <h3 class="font-weight-bolder m-0">10</h3>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-card>
      </div>
      <div class="col-12 mb-3">
        <b-row>
          <b-col class="col-md-8">
            <card-advance-employee-task style="height: 100% !important"/>
          </b-col>
          <b-col class="col-md-4">
            <chartjs-line-chart style="height: 100% !important"/>
          </b-col>
        </b-row>
      </div>
      <div class="col-12">
        <b-row v-if="!modulesLoad">
          <div class="w-100 d-flex align-items-center justify-content-between">
              <div class="pl-2">
                <div class="d-flex justify-content-between ">
            
                  <p style="font-size: large; color: black;">Recommended learning</p>
                  <b-link :to="{ name: 'student_learning' }" class="pl-2 primary" style="color: #E84185 !important; ">
                      View all
                      <feather-icon
                          icon="ArrowRightIcon"
                          size="16"
                      />
                  </b-link>
              </div>
              </div>
              <div class="d-flex justify-content-center">
                <div
                  @click.prevent="slidePrev"
                  :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary ' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-white  p-1 cursor-pointer text-primary'"
                >
                  <!-- <feather-icon icon="ChevronLeftIcon" size="20"/> -->
                  <i class="fa-solid fa-arrow-left" ></i>
                </div>
                <div
                  @click.prevent="slideNext"
                  :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary ' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-white  p-1 cursor-pointer text-primary'"
                >
                  <i class="fa-solid fa-arrow-right"></i>
                  <!-- <feather-icon icon="ChevronRightIcon" size="20"/> -->
                </div>
              </div>
            </div>
          
            <!-- {{ modules.data }} -->
          <hooper :settings="hooperSettings2" ref="carousel2" @slide="updateCarousel2" >
            
            <slide
              class="slide px-1"
              v-for="item in modules.data"
              :key="item.id"
              style="max-height: 270px; width: 310px;"
            >
            <div class="custom-ver-border mt-2  d-flex justify-content-left">
                        
                <div class="img-ver-holder">
                    <img :src="item.cover_image" alt width="100%" />
                </div>
                <div class="p-1 " style="width: 65%;"> 
                    <div class="d-flex justify-content-between">
                        <div class="mb-1">
                            <b-badge pill variant="light" >
                            <span class="text-uppercase pt-1" style="color: #348a3b">popular</span>
                            </b-badge>
                        </div>
                        <div>
                            <small>{{item.total_topics  }}{{item.total_topics  == 1 ? ' Lesson' : ' Lessons' }}</small>
                        </div>
                    </div>
                    <div class="font-weight-bolder" style="color: black;">{{item.name}}</div>
                    <div class="html-wrapper">
                        <small  v-html="item.description"></small>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div  style="color: #f18db6 !important; font-size: small;cursor:pointer ">
                            view
                            <feather-icon
                                icon="ArrowRightIcon"
                                size="16"
                            />
                        </div>
                    </div>
                </div>
                
            </div>
            </slide>
          </hooper>
        </b-row>
      </div>
    </div>
  </div>
</template>
  
  <script>
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BAvatar,
  BLink,
  VBTooltip,
  BSpinner,
  BPagination,
  BFormInput,
  BootstrapVueIcons,
  IconsPlugin,
  BIconArrowUp,
  BIcon
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import CardAdvanceEmployeeTask from "@/views/card/card-advance/CardAdvanceEmployeeTask.vue";
import ChartjsLineChart from "@/views/charts-and-maps/charts/chartjs/ChartjsLineChart.vue";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BLink,
    ToastificationContent,
    BSpinner,
    BPagination,
    vSelect,
    BFormInput,
    BootstrapVueIcons,
    CardAdvanceEmployeeTask,
    IconsPlugin,
    BIconArrowUp,
    Slide,
    Hooper,
    ChartjsLineChart,
    BIcon
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  watch: {},
  computed: {
    ...mapGetters({
      courses: "courses/items",
      modules: "modules/items",
      load: "courses/load",
      modulesLoad: "modules/load"
    })
  },
  data() {
    return {
      hooperSettings2: {
        itemsToShow: 1,
        centerMode: false,
        mouseDrag: false,
        wheelControl: false,
        itemsToSlide: 5,
        infiniteScroll: false,
        breakpoints: {
          500: {
            centerMode: false,
            itemsToSlide: 1,
            itemsToShow: 1
          },
          800: {
            itemsToShow: 4,
            itemsToSlide: 4,
            pagination: "fraction"
          },
          1000: {
            itemsToShow: 5,
            pagination: "fraction"
          }
        }
      }
    };
  },
  methods: {
    makeSlideActive(index, item) {
      this.$refs.carousel.slideTo(index + 1);
    },
    slidePrev(event, ss) {
      this.$refs.carousel2.slidePrev();
    },
    slideNext() {
      this.$refs.carousel2.slideNext();
    },
    updateCarousel(payload) {
      this.filter.course_id =
        payload.currentSlide == 0
          ? null
          : this.courses.data[payload.currentSlide - 1].id;
      this.moduleDetails = null;
      this.$store.dispatch("modules/list", { ...this.filter }).then(_ => {});
    },
    updateCarousel2(payload) {
      this.myCarouselData = payload.currentSlide;
    },
    removeActiveArticleClass() {
      const activeElements = document.querySelectorAll(".active-artical");
      activeElements.forEach(element => {
        element.classList.remove("active-artical");
      });
    },
    updateModuleDetails(item) {
      this.removeActiveArticleClass();
      if (this.moduleDetails && this.moduleDetails.id == item.id) {
        this.moduleDetails = null;
        var element = document.getElementById(`article${item.id}`);
        element.classList.remove("active-artical");
      } else {
        this.moduleDetails = item;
        var element = document.getElementById(`article${item.id}`);
        element.classList.add("active-artical");
      }
    },
    disableWheelScroll() {
      const hooperElement = this.$refs.hooperWrapper;

      hooperElement.addEventListener(
        "wheel",
        event => {
          event.stopPropagation();
          event.preventDefault();
        },
        { passive: false }
      );
    },
    enrol(item) {
      if (this.moduleDetails.assigned) {
        this.$router.push({
          name: "module_introduction",
          params: { id: item.id }
        });
      } else {
        this.$store
          .dispatch("lessonPlans/enrolUser", item.id)
          .then(res => {
            this.$swal({
              icon: "success",
              title: `<h4> ${res.message} </h4>`,
              showConfirmButton: true,
              confirmButtonColor: "#E84185",
              allowOutsideClick: true
            });
          })
          .catch(error => {
            if (error.response) {
              this.$swal({
                icon: "error",
                title: `<h4>${error.response.data.error}</h4>`,
                showConfirmButton: true,
                confirmButtonColor: "#E84185",
                allowOutsideClick: true
              });
            }
          });
      }
    }
  },
  mounted() {
    this.$store.dispatch("courses/list");
    this.$store.dispatch("modules/list");
  }
};
</script>
  
<style lang="css" scoped>
.hooper {
  height: auto !important;
}

.hooper1 .slide {
  /* background-color: black; */
  position: relative !important;
}
.hooper-slide {
  width: 300px;
}
.hooper1 .is-current {
  margin-top: -40px;
}
.nav-arrows {
  background-color: white;
  /* padding: 10px; */
  border-radius: 10%;
  width: 40px;
  height: 40px;
}
.hooper1 .card-title {
  position: absolute !important;
  bottom: 8%;
  left: 13%;
  color: white;
  /* font-size: 1vw; */
}
.hooper1 .card-title h4 {
  color: white;
  margin-bottom: 0;
}

.card-body {
  padding-bottom: 10px !important;
  background-color: #fff;
  border-radius: 15px !important;
}
.card-title {
  margin-bottom: 10px !important;
}
article {
  border-radius: 60px !important;
  background: transparent !important;
}
.card-img-top {
  margin-bottom: -21px !important;
}

.custom-lesson-container {
  position: relative;
  width: 100%;
  border: 2px solid #ffffff;
  border-radius: 20px;
  height: 255px;
  overflow: hidden;
}

.img-holder {
  height: 165px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.img-holder img {
  height: 100%;
  width: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #fff;
  margin-top: -25px;
  border-radius: 20px;
}

.active-artical {
  border: 2px solid #e84185;
  color: #fff !important;
}

.active-artical .content-holder {
  border: 2px solid #e84185 !important;
  color: #fff !important;
  background-color: #e84185 !important;
}

.active-artical h4 {
  color: #fff !important;
}

.active-artical small {
  color: #fff !important;
}
.custom-lesson-container-dark {
  position: relative;
  width: 100%;
  border: 2px solid #2f2f2ffc;
  border-radius: 20px;
  height: 255px;
  overflow: hidden;
}

.custom-lesson-container-dark .img-holder {
  height: 165px;
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.custom-lesson-container-dark .content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #0b0b0bfc;
  margin-top: -25px;
  border-radius: 20px;
}

/* .custom-lesson-container-dark .text-muted{
    color: #9CA3AF !important;
} */

.custom-lesson-container-dark .active-artical small {
  color: #fff !important;
}

/* .active-artical img{
    border: 2px solid #E84185 !important;
} */

.img-holder {
  height: 165px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.img-holder img {
  height: 100%;
  width: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.content-holder {
  position: absolute;
  height: 115px;
  width: 100%;
  z-index: 999;
  padding: 15px;
  background-color: #fff;
  margin-top: -25px;
  border-radius: 20px;
}

.active-artical {
  border: 2px solid #e84185;
  color: #fff !important;
}

.active-artical .content-holder {
  border: 2px solid #e84185 !important;
  color: #fff !important;
  background-color: #e84185 !important;
}

.active-artical h4 {
  color: #fff !important;
}

.active-artical small {
  color: #fff !important;
}

/deep/.hooper-list {
  overflow: visible !important;
  width: 100%;
  height: 100%;
}
.html-wrapper {
  overflow: hidden;
  height: 37px;
}
.custom-ver-border{
        /* border: 2px solid #585858 !important; */
        border-radius: 20px !important;
        height: 150px;
        /* width: 20rem; */
      background-color: #fff;
    }
    .img-ver-holder{
        width: 40%;
        height: 100%;
        justify-content: center;
        overflow: hidden;
    }
    .img-ver-holder img{
        height: 100%;
    width: 100%;
    border-radius: 20px;
    }
</style>
  
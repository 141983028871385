<template>
  <section id="dashboard-ecommerce">
    <!-- <div v-if="this.hasRole('Student')">
      <student-dashboard/>
    </div>
    <div v-if="this.hasRole('Teacher')">
      <teacher-dashboard/>
    </div> -->
  </section>
</template>

<script>
import studentDashboard from '@/views/dashboard/studentDashboard/studentDashboard.vue'
import teacherDashboard from '@/views/dashboard/teacherDashboard/teacherDashboard.vue'
export default {
  
  components: {
    studentDashboard,
    teacherDashboard,
  },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>

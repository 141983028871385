<template>
    <div class="mt-2">
        <div class="d-flex justify-content-between">
            <h3><strong>New Challenges</strong></h3>
            <b-link  class="pl-2 primary" style="color: #E84185 !important; font-weight: 600;">
                View all
            </b-link>
        </div>
        <div class="challenge-container d-flex justify-content-between align-items-center mt-1 p-2">
            <div>

                <img
                    src="../../../assets/images/certificate/pick.jpeg"
                    alt="logo" class="pl-3" width=""
                />
                <strong class="pl-2">Challenge!</strong>
            </div>
            <img src="" alt="bergar">
            <b-button variant="light"
            :class="'rounded-pill px-3'" style="color: #E84185 !important; border-color: #E84185 !important;"> Start</b-button>
        </div>
        <div class="challenge-container d-flex justify-content-between align-items-center mt-1 p-2">
            <div>

                <img
                    src="../../../assets/images/certificate/pick.jpeg"
                    alt="logo" class="pl-3" width=""
                />
                <strong class="pl-2">Challenge!</strong>
            </div>
            <img src="" alt="bergar">
            <b-button variant="light"
            :class="'rounded-pill px-3'" style="color: #E84185 !important; border-color: #E84185 !important;"> Start</b-button>
        </div>
    </div>
</template>
<script>

import {
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
export default {
  components: {
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
  },
  data() {
    return {
        
    }
  },
  computed:{
        ...mapGetters({
            // statistics: "studentDashboard/statistics",
            // loadStatistics: "studentDashboard/loadStatistics",
        }),
    }
}
</script>
<style scoped>
    .challenge-container{
        border: 2px solid #585858 !important;
        border-radius: 1rem !important;
    }
    
</style>
<template>
    <div >
        <!-- <center v-if="loadRecommended">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
        </center> -->
        <div>

            <div class="d-flex justify-content-between">
                <h3><strong>recommended Quests</strong></h3>
                <b-link :to="{ name: 'student_learning' }" class="pl-2 primary" style="color: #E84185 !important; font-weight: 600;">
                    View all
                </b-link>
            </div>
            <div v-if="loadRecommended"  class="d-flex justify-content-center align-items-center" style="height: 25rem;">
                <!-- <center > -->
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                <!-- </center> -->
            </div>
            <b-row v-else>
                <!-- enrolled  -->
                <div class="col-md-6 col-sm-12 mb-2">
                    <div v-if="recommended['enrolled'][0].length > 0" class="custom-hor-border p-5 d-flex align-items-center justify-content-center flex-column text-center" >
                        <strong>
                            Start your challenge, discover your module and enroll now
                        </strong>
                    </div>
                    <div v-else class="custom-hor-border custom-lesson-container-dark cursor-pointer">
                        <div class="img-holder">
                            <img :src="recommended['enrolled'][0].cover_image" alt width="100%" />
                        </div>
                        <div class="content-holder">
                            <div class="w-100 d-flex justify-content-between">
                            <div class="mb-1">
                                <b-badge pill variant="light" >
                                <span class="text-uppercase pt-1" style="color: #0e89b6">ENROLLED</span>
                                </b-badge>
                            </div>
                            <div>
                                <small >Progress: {{recommended['enrolled'][0].completed_topics }}/{{recommended['enrolled'][0].total_topics }}</small>
                            </div>
                            </div>
                            <div class="font-weight-bolder" style="color: #fff;">{{recommended['enrolled'][0].name}}</div>
                            <div class="html-wrapper">
                            <small style="color: #fff;" v-html="recommended['enrolled'][0].description"></small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <!-- new  -->
                    <div class="custom-ver-border d-flex justify-content-left">
                        
                        <div class="img-ver-holder d-flex justify-content-center">
                            <img :src="recommended['new'].cover_image" alt width="100%" height="auto" />
                        </div>
                        <div class="p-1 " style="width: 65%;"> 
                            <div class="d-flex justify-content-between">
                                <div class="mb-1">
                                    <b-badge pill variant="light" >
                                    <span class="text-uppercase pt-1" style="color: #080">new</span>
                                    </b-badge>
                                </div>
                                <div>
                                    <small>{{recommended['new'].total_topics  }}{{recommended['new'].total_topics  == 1 ? ' Lesson' : ' Lessons' }}</small>
                                </div>
                            </div>
                            <div class="font-weight-bolder" style="color: #fff;">{{recommended['new'].name}}</div>
                            <div class="html-wrapper">
                                <small style="color: #fff;" v-html="recommended['new'].description"></small>
                            </div>
                            <div class="d-flex justify-content-end">
                                <div @click="enroll(recommended['new'].id)" style="color: #f18db6 !important; font-size: small;cursor:pointer ">
                                    Enroll now
                                    <feather-icon
                                        icon="ArrowRightIcon"
                                        size="16"
                                    />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <!-- popular  -->
                    <div class="custom-ver-border mt-2 d-flex justify-content-left">
                        
                        <div class="img-ver-holder d-flex justify-content-center">
                            <img :src="recommended['popular'][0].cover_image" alt width="100%" />
                        </div>
                        <div class="p-1 " style="width: 65%;"> 
                            <div class="d-flex justify-content-between">
                                <div class="mb-1">
                                    <b-badge pill variant="light" >
                                    <span class="text-uppercase pt-1" style="color: #ef8e17">popular</span>
                                    </b-badge>
                                </div>
                                <div>
                                    <small>{{recommended['popular'][0].total_topics  }}{{recommended['popular'][0].total_topics  == 1 ? ' Lesson' : ' Lessons' }}</small>
                                </div>
                            </div>
                            <div class="font-weight-bolder" style="color: #fff;">{{recommended['popular'][0].name}}</div>
                            <div class="html-wrapper">
                                <small style="color: #fff;" v-html="recommended['popular'][0].description"></small>
                            </div>
                            <div class="d-flex justify-content-end">
                                <div @click="enroll(recommended['popular'][0].id)" style="color: #f18db6 !important; font-size: small;cursor:pointer ">
                                    Enroll now
                                    <feather-icon
                                        icon="ArrowRightIcon"
                                        size="16"
                                    />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </b-row>
        </div>
    </div>
</template>
<script>

import {
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
    BBadge,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
export default {
  components: {
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BLink,
    BBadge,
  },
  data() {
    return {
        
    }
  },
  computed:{
        ...mapGetters({
            recommended: "studentDashboard/recommended",
            loadRecommended: "studentDashboard/loadRecommended",
        }),
        
    },
    methods: {
        init(){
            this.$store.dispatch('studentDashboard/recommendedQuests').then(_=>{
            })
        },
        enroll(module_id){
            
            this.$store
          .dispatch("lessonPlans/enrolUser",module_id)
          .then(res => {
            this.$swal({
              icon: "success",
              title: `<h4> ${res.message} </h4>`,
              showConfirmButton: true,
              confirmButtonColor: "#E84185",
              allowOutsideClick: true
            });
          })
          .catch(error => {
            if (error.response) {
              this.$swal({
                icon: "error",
                title: `<h4>${error.response.data.error}</h4>`,
                showConfirmButton: true,
                confirmButtonColor: "#E84185",
                allowOutsideClick: true
              });
            }
          });
          
        }
    },
    mounted() {
        this.init();
        
    },
}
</script>

<style scoped>
    .custom-ver-border{
        border: 2px solid #585858 !important;
        border-radius: 20px !important;
        height: 150px;

    }
    .custom-hor-border{
        border: 2px solid #585858 !important;
        border-radius: 20px !important;
        height:324px;
    }
    .img-holder {
        height: 230px;
        /* background-color: #fff; */
        display: flex;
        justify-content: center;
        overflow: hidden;
        width: auto;
    }

.img-holder img {
  height: 100%;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.img-ver-holder{
    width: 40%;
    height: 100%;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
}
.img-ver-holder img{
    height: 100%;
    width: auto;
    border-radius: 20px;
}
.content-holder {
    position: absolute;
    height: 115px;
    width: 91%;
    z-index: 999;
    padding: 15px;
    background-color:  rgb(36 34 34 / 80%);
    margin-top: -25px;
    border-radius: 20px;
}

    
</style>
    <template>
        <div>
            <div v-if="loadStatistics"  class="d-flex justify-content-center align-items-center" style="height: 10rem;">
                <!-- <center > -->
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                <!-- </center> -->
            </div>
            <div v-else class="d-flex justify-content-center  mr-2 ml-2">
                
                <div class="border rounded w-100 p-1" >
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-md-12">

                                    <div class="d-flex justify-content-left pt-1">
                                        <h5>
                                            <strong>User profile</strong>
                                        </h5>
                                        <b-link class="pl-2 primary" style="color: #E84185 !important; font-size: small;">
                                            View profile 
                                            <feather-icon
                                                icon="ArrowRightIcon"
                                                size="16"
                                            />
                                        </b-link>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-sm-12">
                                    <div class="d-flex justify-content-left">
                                        <span class="mt-2">Modules completed:<strong style="font-size: large;"> {{statistics.completed_modules}} </strong></span>
                                    </div>
                                    <div class="d-flex justify-content-left">
                                        <span class="mt-2">Total certificates:<strong style="font-size: large;"> {{statistics.completed_modules}} </strong></span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="pt-3">
                                        <div class="d-flex justify-content-left">
                                            <feather-icon
                                                icon="StarIcon"
                                                size="22"
                                                color="#eba506"
                                            />
                                            <strong class="pl-1">Lv 1 </strong>
                                            <span class="pl-1"> 22 points <span style="color: #8b9399;"> to level up</span> </span>
                                            
                                        </div>
                                        <div class="slider-container">
                                                <input type="range" disabled :min="minVal" :max="maxVal" v-model="value" class="custom-slider">
                                                <!-- <div class="slider-tooltip" :style="{ left: tooltipPosition + '%' }"> {{ value }}</div> -->
                                                <div class="slider-tooltip" :style="{ left: tooltipPosition + '%' }">{{ value }}</div>
                                                <div class="slider-labels">
                                                <span>0</span>
                                                <span>30</span>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-lg-8 col-md-6 col-sm-12">
                                    <div >
                                        <h3  class="pt-3"><strong>Total points earned</strong></h3>
                                        <div class="pt-1" >
                                            <div class="d-flex">
                                                <div class="star-circle">
                                                    <feather-icon
                                                        icon="StarIcon"
                                                        size="22"
                                                        color="#eba506"
                                                    />
                                                </div>
                                                <div class="d-flex align-items-center justify-content-left card-content">
                                                    
                                                    <div class="pl-4 ">
                                                        <center>
                                                            <h4>2</h4>
                                                        </center>
                                                        <span style="font-size: small;">Modules</span>
                                                    </div>
                                                    <div class="ml-1 left-border ">
                                                        <center>
                                                            <h4>2</h4>
                                                        </center>
                                                        <span style="font-size: small;">Quizzes</span>
                                                    </div>
                                                    <div class="ml-1 left-border ">
                                                        <center>
                                                            <h4>2</h4>
                                                        </center>
                                                        <span style="font-size: small;">Assessments</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="d-flex justify-content-center pt-3">
                                        <vue-apex-charts
                                            type="radialBar"
                                            height="160"
                                            width="160"
                                            :options="goalOverviewRadialBarProgress.chartOptions"
                                            :series="goalOverviewRadialBarProgress.series"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import { minValue } from "@vuelidate/validators";
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from "vuex";

import {
    BSpinner,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BFormCheckbox,
        BCard,
        BCardText,
        BCardTitle,
        BButton,
        BLink,
    } from "bootstrap-vue";
export default {

    components: {
        BSpinner,
        VueApexCharts,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BFormCheckbox,
        BCard,
        BCardText,
        BCardTitle,
        BButton,
        BLink,
    },
    data() {
        return {
            minVal:0,
            maxVal:30,
            value: 10,
            tooltipPosition: 0
        }
    },
    methods: {
        updateSliderBackground() {
            const percentage = ((this.value - this.$el.querySelector('input').min) /
                (this.$el.querySelector('input').max - this.$el.querySelector('input').min)) * 100;
                
            this.tooltipPosition = percentage - (percentage/100)*8;
            
            // Update background style
            this.$el.querySelector('.custom-slider').style.background = `linear-gradient(90deg, #ff4fa7 ${percentage}%, #2c2c2c ${percentage}%)`;
        },
        init(){
            this.$store.dispatch('studentDashboard/studentStatistics').then(_=>{
            })
        },
    },
    mounted() {
        this.init();
        this.updateSliderBackground();
        
    },

    computed:{
        ...mapGetters({
            statistics: "studentDashboard/statistics",
            loadStatistics: "studentDashboard/loadStatistics",
        }),
        goalOverviewRadialBarProgress(){
            return {
            series: [this.statistics.student_progress],
            chartOptions: {
            chart: {
                // height: 90,
                type: 'radialBar',
                toolbar: {
                show: false
                }
            },
            plotOptions: {
                radialBar: {
                startAngle: -135,
                endAngle: 225,
                    hollow: {
                    margin: 0,
                    size: '65%',
                    background: this.$store.state.appConfig.layout.skin == 'dark' ? '#000' : '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                    dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 5,
                    opacity: 0.24
                    }
                },
                track: {
                    background: this.$store.state.appConfig.layout.skin == 'dark' ? '#000' : '#fff',
                    strokeWidth: '60%',
                    margin: 0, // margin is in pixels
                    dropShadow: {
                    enabled: true,
                    bottom: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.55
                    }
                },
            
                dataLabels: {
                    show: true,
                    name: {
                    offsetY: 0,
                    show: false,
                    color: '#888',
                    fontSize: '22px'
                    },
                    value: {
                    formatter: function(val) {
                        return parseInt(val)+'%';
                    },
                    color: this.$store.state.appConfig.layout.skin == 'dark' ? '#fff' : '#111',
                    fontSize: '15px',
                    offsetY: 5,
                    show: true,
                    }
                }
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 1,
                gradientToColors: ['#E84185'],
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
                }
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['Percent'],
            },
            }
        }
    }
}
</script>
<style scoped>
    .star-circle{
        position: absolute;
        left: -12px;
        bottom: 6px;
        width: 55px;
        height: 55px;
        border: 3px solid #000; 
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1c1c1c;
    }
    
    .card-content{
        background-color: #1c1c1c;
        border-radius: 10px;
        width: auto;
        height: 70px;
        padding-right: 10px;
    }
    .left-border{
        border-left: 0.15rem solid #444;
        padding-left: 10px;
    }
    .slider-container {
        position: relative;
        width: 85%;
        padding-top: 20px;
        /* padding: 20px; */
    }

.custom-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    background: linear-gradient(90deg, #ff4fa7 40%, #2c2c2c 40%);
    outline: none;
    border-radius: 5px;
    transition: background 0.15s ease-in-out;
}

.custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background-color: #000;
    border: 2px solid #ff4fa7;
    border-radius: 50%;
  /* cursor: pointer; */
}

.custom-slider::-moz-range-thumb {
    /* width: 30px;
    height: 30px;
    background-color: #000; 
    border: 2px solid #ff4fa7;
    border-radius: 50%;
    cursor: pointer; */
}

.slider-tooltip {
    position: absolute;
    top: 20px;
    color: white;
    font-size: 12px;
    padding-left: 0px;
    margin-left: 6px;
    /* transform: translateX(-80%); */
}

.border{
    border: 2px solid #585858 !important;
}
.slider-labels {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 14px;
    padding-top: 5px;
}
@media (min-width: 992px) {
    .star-circle {
        bottom: 30px;
    }
}
/* @media (min-width: 695px) {
    .star-circle {
        bottom: 6px;
    }
    
} */
@media (min-width: 1050px) {
    .star-circle {
        bottom: 30px;
    }
}
@media (min-width: 750px) {
    /* .card-content{
        width: 100%;
    }
    .left-border{
        width: 33.3%;
    } */
}
</style>